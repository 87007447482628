import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51')
];

export const server_loads = [0];

export const dictionary = {
		"/": [4],
		"/account": [~5,[2]],
		"/account/card": [~6,[2]],
		"/account/fees": [~7,[2]],
		"/account/loans": [~8,[2]],
		"/account/pickup": [~9,[2]],
		"/account/requests": [~10,[2]],
		"/admin": [~11],
		"/bookmarks": [~12],
		"/bookmarks/list": [~13],
		"/demo": [14],
		"/demo/citation": [15],
		"/demo/foo": [16],
		"/demo/loki": [~17],
		"/demo/paraglide": [18],
		"/demo/rvk": [19],
		"/example": [~20],
		"/example/bento": [21],
		"/example/internal": [22],
		"/example/list": [23],
		"/example/notes": [24],
		"/example/sentry-example": [25],
		"/example/test": [26],
		"/example/test/email": [27],
		"/example/test/skeleton": [28],
		"/forms": [~29],
		"/forms/av": [~30],
		"/forms/request": [~31],
		"/history": [~32],
		"/id/[mms_id]": [33],
		"/journals": [34],
		"/login": [35],
		"/new": [36,[3]],
		"/new/books": [38,[3]],
		"/new/databases": [39,[3]],
		"/new/journals": [40,[3]],
		"/new/[nel]": [37,[3]],
		"/openurl": [41],
		"/poweredby": [42],
		"/register": [43],
		"/register/success": [44],
		"/resources": [45],
		"/search": [~46],
		"/search/1": [47],
		"/search/new": [48],
		"/search/options": [49],
		"/subscriptions": [~50],
		"/taxonomy": [51]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: universal_hooks.reroute || (() => {}),
	transport: universal_hooks.transport || {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';